/* .User{
    position:absolute;
    left: 250px;
    bottom: 100px;
    
}
.Data1 {
    position:relative;
    left:250px;
    top:20px

    
  }
.View1{
    position: relative;
    top:50px
}

.r1{
 text-align: center;
}
.r11{
    text-align: center;
}
.Icon1{
    position: relative;
    bottom:90px;
} */

.driver-popover.driverjs-theme {
    background-color: #fde047;
    color: #000;
  }
  
  .driver-popover.driverjs-theme .driver-popover-title {
    font-size: 20px;
  }
  
  .driver-popover.driverjs-theme .driver-popover-title,
  .driver-popover.driverjs-theme .driver-popover-description,
  .driver-popover.driverjs-theme .driver-popover-progress-text {
    color: #000;
  }
  
  .driver-popover.driverjs-theme button {
    flex: 1;
    text-align: center;
    background-color: #000;
    color: #ffffff;
    border: 2px solid #000;
    text-shadow: none;
    font-size: 14px;
    padding: 2px 2px;
    border-radius: 6px;
  }
  
  .driver-popover.driverjs-theme button:hover {
    background-color: #000;
    color: #ffffff;
  }
  
  .driver-popover.driverjs-theme .driver-popover-navigation-btns {
    justify-content: space-between;
    gap: 3px;
  }
  
  .driver-popover.driverjs-theme .driver-popover-close-btn {
    color: #9b9b9b;
  }
  
  .driver-popover.driverjs-theme .driver-popover-close-btn:hover {
    color: #000;
  }
  
  .driver-popover.driverjs-theme .driver-popover-arrow-side-left.driver-popover-arrow {
    border-left-color: #fde047;
  }
  
  .driver-popover.driverjs-theme .driver-popover-arrow-side-right.driver-popover-arrow {
    border-right-color: #fde047;
  }
  
  .driver-popover.driverjs-theme .driver-popover-arrow-side-top.driver-popover-arrow {
    border-top-color: #fde047;
  }
  
  .driver-popover.driverjs-theme .driver-popover-arrow-side-bottom.driver-popover-arrow {
    border-bottom-color: #fde047;
  }